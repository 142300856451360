import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";

import { ROUTER_URLS, ROUTE_NAMES } from "../core/types/router/enums";
import { useAuthStore } from "@/stores/authStore";

const routes = [
  {
    path: ROUTER_URLS.LOGIN,
    name: ROUTE_NAMES.LOGIN,
    component: () => import("../views/Auth/LoginPage.vue"),
    meta: { withAuthCheck: false },
  },
  {
    path: ROUTER_URLS.HOME,
    name: ROUTE_NAMES.DASHBOARD,
    component: () => import("../views/Home/Home.vue"),
    meta: { withAuthCheck: true },
  },
  {
    path: ROUTER_URLS.SINGLE_CONTAINER,
    name: ROUTE_NAMES.SINGLE_CONTAINER,
    component: () => import("../views/Container/Container.vue"),
    meta: { withAuthCheck: true, dontClearConnectionTracker: true },
  },
  {
    path: ROUTER_URLS.CONTAINERS,
    name: ROUTE_NAMES.CONTAINERS,
    component: () => import("../views/AllContainers/Containers.vue"),
    meta: { withAuthCheck: true, dontClearConnectionTracker: true },
  },
  {
    path: ROUTER_URLS.ANALYTICS,
    name: ROUTE_NAMES.ANALYTICS,
    component: () => import("../views/Analytics/Analytics.vue"),
    redirect: {
      path: ROUTER_URLS.ANALYTICS + ROUTER_URLS.ANALYTICS_PARAMETERS,
    },
    meta: { withAuthCheck: true },
    children: [
      {
        path: ROUTER_URLS.ANALYTICS + ROUTER_URLS.ANALYTICS_PARAMETERS,
        name: ROUTE_NAMES.ANALYTICS_PARAMETERS,
        component: () => import("../views/Analytics/Parameters/Parameters.vue"),
        meta: { withAuthCheck: true },
      },
      {
        path: ROUTER_URLS.ANALYTICS + ROUTER_URLS.ANALYTICS_CHANGING_PARAMETERS,
        name: ROUTE_NAMES.ANALYTICS_CHANGING_PARAMETERS,
        component: () =>
          import("../views/Analytics/ChangeParameters/ChangeParameters.vue"),
        meta: { withAuthCheck: true },
      },
      {
        path: ROUTER_URLS.ANALYTICS + ROUTER_URLS.ANALYTICS_CONNECTION,
        name: ROUTE_NAMES.ANALYTICS_CONNECTION,
        component: () => import("../views/Analytics/Connection/Connection.vue"),
        meta: { withAuthCheck: true },
      },
      {
        path: ROUTER_URLS.ANALYTICS + ROUTER_URLS.ANALYTICS_OPERATION_MODE,
        name: ROUTE_NAMES.ANALYTICS_OPERATION_MODE,
        component: () =>
          import("../views/Analytics/OperationMode/OperationMode.vue"),
        meta: { withAuthCheck: true },
      },
      {
        path: ROUTER_URLS.ANALYTICS + ROUTER_URLS.ANALYTICS_DETAILS,
        name: ROUTE_NAMES.ANALYTICS_DETAILS,
        component: () =>
          import("../views/Analytics/Details/FansDetails/FansDetails.vue"),
        meta: { withAuthCheck: true },
      },
    ],
  },
  // return when dashboard will be done
  // {path: '/', redirect: ROUTE_NAMES.DASHBOARD},
  { path: "/", redirect: ROUTE_NAMES.CONTAINERS },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (to.meta.withAuthCheck) {
      const { isAuth } = useAuthStore();

      return isAuth || { name: ROUTE_NAMES.LOGIN };
    }
  }
);

export default router;
