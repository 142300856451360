import { defineStore } from "pinia";
import type {
  State,
  Getters,
  Actions,
} from "@/core/types/stores/auth_store.interface";
import { AuthService, LocalStorageService } from "@/services";
import { ROUTE_NAMES } from "@/core/types/router/enums";
import { LOCAL_STORAGE_KEYS } from "@/core/types/api/enums";
import { useContainerStore } from "./containerStore";

export const useAuthStore = defineStore<"auth", State, Getters, Actions>(
  "auth",
  {
    state: () => ({
      isAuth: !!LocalStorageService.read(LOCAL_STORAGE_KEYS.AUTH_TOKEN),
      token: LocalStorageService.read(LOCAL_STORAGE_KEYS.AUTH_TOKEN) || "",
    }),
    actions: {
      setIsAuth(value) {
        this.isAuth = value;
      },
      setToken(token) {
        this.token = token;
      },
      async login(payload) {
        if (!payload.login.length || !payload.password.length) return;

        const res = (await AuthService.login(payload))?.data;
        if (!res) return false;

        this.isAuth = !!res.is_approved;
        this.token = res.user_token;

        LocalStorageService.set(LOCAL_STORAGE_KEYS.AUTH_TOKEN, res.user_token);

        return true;
      },
      logout() {
        const containersStore = useContainerStore();

        LocalStorageService.remove(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
        containersStore.resetStore();
      },
    },
  }
);
