<template>
  <div class="modal_wrap" @click.self="() => removeModal()">
    <!-- <v-slide-y-reverse-transition>
      <div v-show="show"> -->
    <slot />
    <!-- </div>
    </v-slide-y-reverse-transition>  -->
  </div>
</template>

<script setup lang="ts">
import { useModalStore } from '@/stores/modalStore';
import { onUnmounted } from 'vue';
import { onMounted } from 'vue';
import { switchDocumentScroll } from '@/helpers';

const { removeModal } = useModalStore();

onMounted(() => {
  switchDocumentScroll(false);
});

onUnmounted(() => {
  switchDocumentScroll(true);
});
</script>

<style scoped lang="scss">
@import './ModalLayout.scss';
</style>