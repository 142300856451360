// enums
import { SIDE_MENU_ICON_TYPE } from '../../types/components/enums';
// constants
import { SIDE_MENU_ICONS } from '../icons/arctic_icons.constant';
// interfaces
import type { ISidebarIcon } from '@/core/types/components/interfaces';

export const SIDE_MENU_CONTROLS: ISidebarIcon[] = [
  SIDE_MENU_ICONS[SIDE_MENU_ICON_TYPE.SIDE_MENU_CONTAINER],
  SIDE_MENU_ICONS[SIDE_MENU_ICON_TYPE.SIDE_MENU_TREND],
  SIDE_MENU_ICONS[SIDE_MENU_ICON_TYPE.SIDE_MENU_LOGOUT_RED],
];