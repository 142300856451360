export enum MODAL_NAMES {
  PARAMETERS = 'parameters',
};

export enum PARAMETER_UNITS {
  ROTATION = 'RPM',
  FREQ = 'Hz',
  TEMP = '°C',
  PIN = 'PIN',
  MODE = 'Mode',
};

export enum MANUAL_PARAMETERS_MODEL_KEYS {
  FAN_SPEED = 'fanSpeed',
  PUMP_1_FREQ = 'pump1Freq',
  PUMP_2_FREQ = 'pump2Freq',
};

export enum ADDITIONAL_PARAMETERS_MODEL_KEYS {
  FAN_SPEED_MAX = 'FAN_SPEED_MAX',
  FAN_SPEED_MIN = 'FAN_SPEED_MIN',
  FAN_T_MAX = 'FAN_T_MAX',
  FAN_T_MIN = 'FAN_T_MIN',
  PUMP_FREQ_MAX = 'PUMP_FREQ_MAX',
  PUMP_FREQ_MIN = 'PUMP_FREQ_MIN',
  PUMP_T_MAX = 'PUMP_T_MAX',
  PUMP_T_MIN = 'PUMP_T_MIN',
};
