export enum CHART_TYPE {
  PIE_MODE = "PIE_MODE",
  PIE_PARAMETERS = "PIE_PARAMETERS",
  PIE_CONNECTING = "PIE_CONNECTING",

  LINE_TIME = "LINE_TIME",
  LINE = "LINE",
  AREA = "AREA",

  DETAILS = "DETAILS",
}

export enum LINE_CHART_TYPES {
  PUMP = "pump",
  FAN = "fan",
  LIQUID = "liquid",
  TEMPERATURE = "temperature",
}
