export enum ICON_TYPE {
	CLOSE = 'CLOSE',
	EYE = 'EYE',
	LOGO = 'LOGO',
	EYE_OFF = 'EYE_OFF',
	FILTERS = 'FILTERS',
	DRAWER_WHITE = 'DRAWER_WHITE',
	CALENDAR_WHITE = 'CALENDAR_WHITE'
}

export enum SIDE_MENU_ICON_TYPE {
	SIDE_MENU_TREND = 'SIDE_MENU_TREND',
	SIDE_MENU_CONTAINER = 'SIDE_MENU_CONTAINER',
	SIDE_MENU_LOGOUT_RED = 'SIDE_MENU_LOGOUT_RED',
};
