export enum ANALYTICS_NAVIGATION_TYPES {
  CHANGE_PARAMETERS = "changing-parameters",
  OPERATION_MODE = "operation-mode",
  CONNECTION = "connection",
  PARAMETERS = "parameters",
  DETAILS = "details",
}

export enum ANALYTICS_COLORS_PALETTE {
  DARK_BLUE = "#181E36",
  BLUE = "#008FFB",
  LIGHT_BLUE = "#AEF9FF",
  GREEN = "#9BDEAC",
  DARK_GREEN = "#18AC9C",
  LIGHT_GREEN = "#00BADA",
  PINK = "#DC6BDB",
  LIGHT_PINK = "#F4B4D8",
  WHITE = "#FFF",
  BLACK = "#000",
}

export enum LEGEND_TYPES {
  CHANGE_PARAMETERS = "parameters",
  OPERATION_MODE = "mode",
  CONNECTION = "connection",
}

export enum ANALYTIC_REQ_TYPE {
  PARAMETERS = "parameters",
  PARAMETER_PUMPS = "parameter/pumps",
  PARAMETER_FANS = "parameter/fans",
  PARAMETER_LIQUIDS = "parameter/liquids",
  PARAMETER_TEMP = "parameter/temp",
  CONNECTION = "connection",
  OPERATION_MODE = "operation-mode",
  CHANGING_PARAMETER = "changing-parameters",
  SWITCH = "switching-chart",
}
