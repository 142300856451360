<template>
  <ModalWindow header="Change parameters">
    <div class="parameters">
      <ModeSwitcher
        :current-mode="currentUserMode"
      />

      <div class="parameters__list">
        <ManualParametersList
          :is-active="!currentUserMode"
          :items="manualParametersItems"
        />

        <div class="parameters__list--additional-wide">
          <AdditionalParametersList 
            :is-active="!!currentUserMode"
            :items="additionalParametersItems.slice(0, 4)"
          />

          <AdditionalParametersList 
            :is-active="!!currentUserMode"
            :items="additionalParametersItems.slice(-3)"
          />
        </div>

        <div 
          :class="`parameters__list--additional-tablet ${!currentUserMode ? 'hide-on-small-screens' : ''}`"
        >
          <AdditionalParametersList 
            :is-active="!!currentUserMode"
            :items="additionalParametersItems"
          />
        </div>
      </div>

      <div class="parameters__pin">
        <div :class="`parameters__pin--input ${isValidationError ? 'error' : ''}`">
          <span>{{ PARAMETER_UNITS.PIN }}</span>

          <input
            type="number" 
            placeholder="__"
            @input="setPIN"
          />
        </div>

        <span 
          v-if="isValidationError"
          class="error-text"
        >field is required</span>
      </div>

      <div class="parameters__btns">
        <OutlinedButton 
          text="Cancel" 
          :bg-color="BG_COLORS.TRANSPARENT" 
          :color="TEXT_COLORS.WHITE" 
          @click="removeModal" 
        />

        <FilledButton 
          text="Apply" 
          :bg-color="BG_COLORS.GREEN" 
          :color="TEXT_COLORS.WHITE"
          :disabled="isApplyDisabled"
          @click="() => sendParameters()" 
        />
      </div>
    </div>
  </ModalWindow>
</template>

<script setup lang="ts">
import ModalWindow from '@/layouts/ModalWindowLayout/ModalWindow.vue';
import ManualParametersList from './ManualParametersList/ManualParametersList.vue';
import AdditionalParametersList from './AdditionalParametersList/AdditionalParametersList.vue';
import ModeSwitcher from './ModeSwitcher/ModeSwitcher.vue';
import FilledButton from '@/components/shareable/buttons/FilledButton/FilledButton.vue';
import OutlinedButton from '@/components/shareable/buttons/OutlinedButton/OutlinedButton.vue';

import {
  BG_COLORS,
  TEXT_COLORS,
  PARAMETER_UNITS,
  MANUAL_PARAMETERS_MODEL_KEYS,
  ADDITIONAL_PARAMETERS_MODEL_KEYS
 } from '@/core/types/components/enums';

import { useModalStore } from '@/stores/modalStore';
import { useContainerStore } from '@/stores/containerStore';

import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';

const { removeModal } = useModalStore();
const { 
  sendNewParameters,
  setPinCode,
  setUserParameters,
} = useContainerStore();

const route = useRoute();

const { 
  currentMode,
  currentUserMode,
  manualModeData,
  autoModeData,
  manualUserModelData,
  autoUserModelData,
  getAdditionalExistingParameters,
  activeContainerId
} = storeToRefs(useContainerStore());

const isApplyDisabled = ref<boolean>(true);
const isValidationError = ref<boolean>(false);

const manualParametersItems = computed(() => {
  return [
    {
      title: 'Fan',
      unit: PARAMETER_UNITS.ROTATION,
      manualParameterName: MANUAL_PARAMETERS_MODEL_KEYS.FAN_SPEED,
      actualValue: manualModeData.value?.fanSpeed || 0,
      userValue: manualUserModelData.value?.fanSpeed || 0, 
    },
    {
      title: 'Pump 1',
      unit: PARAMETER_UNITS.FREQ,
      manualParameterName: MANUAL_PARAMETERS_MODEL_KEYS.PUMP_1_FREQ,
      actualValue: manualModeData.value?.pump1Freq || 0,
      userValue: manualUserModelData.value?.pump1Freq || 0,
    },
    {
      title: 'Pump 2',
      unit: PARAMETER_UNITS.FREQ,
      manualParameterName: MANUAL_PARAMETERS_MODEL_KEYS.PUMP_2_FREQ,
      actualValue: manualModeData.value?.pump2Freq || 0,
      userValue: manualUserModelData.value?.pump2Freq || 0,
    },
  ];
});

const additionalParametersItems = computed(() => {
  return [
    {
      title: 'Max Fan Speed',
      unit: PARAMETER_UNITS.ROTATION,
      additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_SPEED_MAX,
      actualValue: getAdditionalExistingParameters.value?.FAN_SPEED_MAX || 0,
      userValue: autoUserModelData.value?.FAN_SPEED_MAX || 0,
    },
    {
      title: 'Min Fan Speed',
      unit: PARAMETER_UNITS.ROTATION,
      additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_SPEED_MIN,
      actualValue: getAdditionalExistingParameters.value?.FAN_SPEED_MIN || 0,
      userValue: autoUserModelData.value?.FAN_SPEED_MIN || 0,
    },
    {
      title: 'Fan Target Temperature',
      unit: PARAMETER_UNITS.TEMP,
      additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_T_MAX,
      actualValue: getAdditionalExistingParameters.value?.FAN_T_MAX || 0,
      userValue: autoUserModelData.value?.FAN_T_MAX || 0,
    },
    // {
    //   title: 'Min Speed Fan Temp.',
    //   unit: PARAMETER_UNITS.TEMP,
    //   additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_T_MIN,
    //   actualValue: getAdditionalExistingParameters.value?.FAN_T_MIN || 0,
    // },
    {
      title: 'Max Pump Freq.',
      unit: PARAMETER_UNITS.FREQ,
      additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_FREQ_MAX,
      actualValue: getAdditionalExistingParameters.value?.PUMP_FREQ_MAX || 0,
      userValue: autoUserModelData.value?.PUMP_FREQ_MAX || 0,
    },
    {
      title: 'Min Pump Freq.',
      unit: PARAMETER_UNITS.FREQ,
      additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_FREQ_MIN,
      actualValue: getAdditionalExistingParameters.value?.PUMP_FREQ_MIN || 0,
      userValue: autoUserModelData.value?.PUMP_FREQ_MIN || 0,
    },
    {
      title: 'Max Freq Pump Temp.',
      unit: PARAMETER_UNITS.TEMP,
      additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_T_MAX,
      actualValue: getAdditionalExistingParameters.value?.PUMP_T_MAX || 0,
      userValue: autoUserModelData.value?.PUMP_T_MAX || 0,
    },
    {
      title: 'Min Freq Pump Temp.',
      unit: PARAMETER_UNITS.TEMP,
      additionalParameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_T_MIN,
      actualValue: getAdditionalExistingParameters.value?.PUMP_T_MIN || 0,
      userValue: autoUserModelData.value?.PUMP_T_MIN || 0,
    },
  ];
});

function sendParameters() {
  if (isApplyDisabled.value) {
    isValidationError.value = true;

    return;
  };

  const routerId: string = route.params.id as string;

  sendNewParameters(activeContainerId.value || parseInt(routerId.split('-')[1], 10));
  removeModal();
};

function setPIN(e: Event) {
  if (!e || e.target === null) return;

  const target = e.target as HTMLInputElement;

  isApplyDisabled.value = !target.value.length;
  isValidationError.value = false;

  setPinCode(+target.value);
};

onMounted(() => {
  if (manualModeData.value === null || autoModeData.value === null) return;

  setUserParameters(
    currentMode.value, 
    manualModeData.value, 
    autoModeData.value
  );
});

</script>

<style scoped lang="scss">
@import './ChangeParameters.scss';
</style>
