<template>
  <div class="animated-bg">
    <div class="animated-bg__circle one"></div>
    <div class="animated-bg__circle two"></div>
    <div class="animated-bg__circle three"></div>
    <div class="animated-bg__circle four"></div>
    <div class="animated-bg__circle five"></div>
    <div class="animated-bg__circle six"></div>
    <div class="animated-bg__circle seven"></div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
@import './AnimatedBackground.scss';
</style>