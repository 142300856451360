<template>
  <button
    :class="`outlined-button ripple ${color} ${bgColor} ${borderColor}`"
    :style="{ width: width || '100%', height: height || '42px'}"
    @click="$event => emit('click', $event)"
  >
    {{ text }}
  </button>
</template>

<script setup lang="ts">
import type { IButtonProps, IButtonEmits } from '@/core/types/components/interfaces';

defineProps<IButtonProps>();
const emit = defineEmits<IButtonEmits>();
</script>

<style scoped lang="scss">
@import './OutlinedButton.scss';
</style>