export enum ROUTE_NAMES {
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    CONTAINERS = 'containers',
    SINGLE_CONTAINER = 'single-container',
    ANALYTICS = 'analytics',
    PROFILE = 'profile',
    FAQ = 'faq',
    SUPPORT = 'support',
    LOGOUT = 'logout',
    ANALYTICS_OPERATION_MODE = 'operation-mode',
    ANALYTICS_CHANGING_PARAMETERS = 'changing-parameters',
    ANALYTICS_CONNECTION = 'connection',
    ANALYTICS_PARAMETERS = 'parameters',
    ANALYTICS_DETAILS = 'details',
};
