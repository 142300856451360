import { API } from "@/api/api";
import { ENDPOINTS } from "@/core/types/api/enums";
import type { ILoginRequestBody, ILoginResponse } from "@/core/types/api/interfaces";
import { errorHandledTryCatch } from "@/helpers";

class AuthService {
  static login = async (body: ILoginRequestBody) => 
    errorHandledTryCatch<ILoginResponse>(() => API.post(ENDPOINTS.LOGIN, {login: JSON.stringify(body)}));

  // static register = async (body: IRegisterRequest) => 
    // errorHandledTryCatch<IRegisterResponse>(() => API.post(ENDPOINTS.REGISTER, body));
  
  // static logout = async () => {};
  //   // errorHandledTryCatch<string>(() => API.get(ENDPOINTS.LOGOUT));

  // static refreshToken = async () => {};
  //   // errorHandledTryCatch<ILoginResponse>(() => API.post(ENDPOINTS.REFRESH_TOKEN));

  // static currentUser = async () => {};
  //   // errorHandledTryCatch<IRegisterResponse>(() => API.post(ENDPOINTS.CURRENT_USER));
};

export { AuthService };