import { 
  PARAMETER_UNITS,
  MANUAL_PARAMETERS_MODEL_KEYS,
  ADDITIONAL_PARAMETERS_MODEL_KEYS
} from "@/core/types/components/enums";

export const MANUAL_PARAMETERS_ITEMS = [
  {
    title: 'Fan',
    unit: PARAMETER_UNITS.ROTATION,
    parameterName: MANUAL_PARAMETERS_MODEL_KEYS.FAN_SPEED,
    modelProp: 'fanSpeed',
  },
  {
    title: 'Pump 1',
    unit: PARAMETER_UNITS.FREQ,
    parameterName: MANUAL_PARAMETERS_MODEL_KEYS.PUMP_1_FREQ,
    modelProp: 'pump1Freq',
  },
  {
    title: 'Pump 2',
    unit: PARAMETER_UNITS.FREQ,
    parameterName: MANUAL_PARAMETERS_MODEL_KEYS.PUMP_2_FREQ,
    modelProp: 'pump2Freq',
  },
];

export const ADDITIONAL_PARAMETERS_ITEMS = [
  {
    title: 'Max Fan Speed',
    unit: PARAMETER_UNITS.ROTATION,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_SPEED_MAX,
  },
  {
    title: 'Min Fan Speed',
    unit: PARAMETER_UNITS.ROTATION,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_SPEED_MIN,
  },
  {
    title: 'Max Speed Fan Temp.',
    unit: PARAMETER_UNITS.TEMP,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_T_MAX,
  },
  {
    title: 'Min Speed Fan Temp.',
    unit: PARAMETER_UNITS.TEMP,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.FAN_T_MIN,
  },
  {
    title: 'Max Pump Freq.',
    unit: PARAMETER_UNITS.FREQ,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_FREQ_MAX,
  },
  {
    title: 'Min Pump Freq.',
    unit: PARAMETER_UNITS.FREQ,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_FREQ_MIN,
  },
  {
    title: 'Max Speed Pump Temp.',
    unit: PARAMETER_UNITS.TEMP,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_T_MAX,
  },
  {
    title: 'Min Speed Pump Temp.',
    unit: PARAMETER_UNITS.TEMP,
    parameterName: ADDITIONAL_PARAMETERS_MODEL_KEYS.PUMP_T_MIN,
  },
];
