import fan from '../../../assets/icons/container/fan.svg';
import pump from '../../../assets/icons/container/pump.svg';
import tank from '../../../assets/icons/container/tank.svg';

import { CONTAINER_ITEM_TYPE } from '../../types/components/enums';

export const CONTAINER_ITEM_IMGS = {
  [CONTAINER_ITEM_TYPE.FAN]: fan,
  [CONTAINER_ITEM_TYPE.PUMP]: pump,
  [CONTAINER_ITEM_TYPE.TANK]: tank,
};