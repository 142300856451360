import axios from "axios";
import type {
  IAnalyticPayload,
  IGetChangingParametersResponse,
  IGetConnectionResponse,
  IGetOperationResponse,
  IGetParameterFans,
  IGetParameterLiquid,
  IGetParameterPumps,
  IGetParameterTemperature,
  IResponseGetParameters,
} from "@/core/types/api/interfaces";
import { ENDPOINTS } from "@/core/types/api/enums";
import { errorHandledTryCatch, getAnalyticQueryParams } from "@/helpers";

// At the moment, this is necessary because our analytics data is located on another server
export const ANALYTIC_API = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL_SECOND,
  headers: {},
});
//

class AnalyticService {
  static getParameters = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IResponseGetParameters>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.PARAMETERS
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getPumps = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IGetParameterPumps>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.PARAMETER_PUMPS
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getLiquids = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IGetParameterLiquid>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.PARAMETER_LIQUIDS
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getFans = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IGetParameterFans>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.PARAMETER_FANS
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getTemperatureInput = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IGetParameterTemperature>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.PARAMETER_TEMP_INPUT
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getTemperatureOutput = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IGetParameterTemperature>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.PARAMETER_TEMP_OUTPUT
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getConnection = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IGetConnectionResponse>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.CONNECTION
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getOperationMode = async (payload: IAnalyticPayload) => {
    const { containerIds, startDate, stopDate } = payload;

    return errorHandledTryCatch<IGetOperationResponse>(() =>
      ANALYTIC_API.get(
        `${
          ENDPOINTS.OPERATION_MODE
        }?containerIDs=${containerIds}${getAnalyticQueryParams(
          startDate,
          stopDate
        )}`
      )
    );
  };

  static getChangingParameters = async (payload: IAnalyticPayload) => {
    return errorHandledTryCatch<IGetChangingParametersResponse>(() =>
      ANALYTIC_API.get(
        `${ENDPOINTS.CHANGING_PARAMETERS}?containerIDs=${
          payload.containerIds
        }${getAnalyticQueryParams(payload.startDate, payload.stopDate)}`
      )
    );
  };
}

export { AnalyticService };
