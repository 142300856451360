export const getAnalyticQueryParams = (
  startDate?: string,
  stopDate?: string
): string => {
  const params = new URLSearchParams();

  if (startDate) {
    params.append("start", startDate);
  }

  if (stopDate) {
    params.append("stop", stopDate);
  }

  return params.toString() ? `&${params.toString()}` : "";
};
