<template>
  <div
    class="modal-window"
  >
    <div class="modal-window__header">
      <h3>{{ header }}</h3>
      
      <CloseButton
        @click="removeModal"
      />
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
import type { IModalParameters } from '@/core/types/components/interfaces';
import CloseButton from '@/components/shareable/buttons/CloseButton/CloseButton.vue';

import { useModalStore } from '@/stores/modalStore';

defineProps<IModalParameters>();

const { removeModal } = useModalStore();
</script>

<style scoped lang="scss">
@import './ModalWindow.scss';
</style>