<template>
  <div class="mode-switcher">
    <div 
      :class="`mode-switcher__mode ${!currentMode ? 'active' : ''}`"
      @click="() => setCurrentMode(0)"
    >
      Manual
    </div>

    <div 
      :class="`mode-switcher__mode ${currentMode ? 'active' : ''}`"
      @click="() => setCurrentMode(1)"
    >
      Automatic
    </div>

    <div 
      :class="`mode-switcher__indicator ${currentMode ? 'auto' : 'manual'}`"
    ></div>
  </div>
</template>

<script setup lang="ts">
import type { IModeSwitcherProps } from '@/core/types/components/interfaces';

import { useContainerStore } from '@/stores/containerStore';
defineProps<IModeSwitcherProps>();

const { setCurrentMode } = useContainerStore();
</script>

<style scoped lang="scss">
@import './ModeSwitcher.scss';
</style>