import { FILTER_KEYS } from "@/core/types/components/enums/filter.enum";

export const getTransformFilterContainers = (containers: number[]) => {
  if (!containers.length) {
    return [];
  }

  return containers.map((containerNum) => ({
    filterNum: containerNum,
    name: `Container ${containerNum}`,
    filterKeys: [FILTER_KEYS.CONTAINER_NUMBER],
  }));
};
