// constants
import { IMAGE_ALT } from '../general';
// icons
import eyeIcon from '@/assets/icons/general/eye.svg';
import logoIcon from '@/assets/icons/general/logo.svg';
import eyeOffIcon from '@/assets/icons/general/eye-off.svg';
import logoutRedIcon from '@/assets/icons/sidebar/logout-red.svg';
import trendGrayIcon from '@/assets/icons/sidebar/trend-gray.svg';
import filtersIcon from '@/assets/icons/general/filters-icon.svg';
import trendGreenIcon from '@/assets/icons/sidebar/trend-green.svg';
import drawerWhiteIcon from '@/assets/icons/general/drawer-white.svg';
import containerGrayIcon from '@/assets/icons/sidebar/container-gray.svg';
import calendarWhiteIcon from '@/assets/icons/general/calendar-white.svg';
import containerGreenIcon from '@/assets/icons/sidebar/container-green.svg';
import closeIcon from '@/assets/icons/general/closing-icon.svg';
// enums
import { CONTROL_TYPES, ICON_TYPE, SIDE_MENU_ICON_TYPE } from '@/core/types/components/enums';
// interfaces
import type { IGeneralIcon, ISidebarIcon } from '@/core/types/components/interfaces';

export const ARCTIC_ICONS:  Record<ICON_TYPE, IGeneralIcon> = {
	[ICON_TYPE.CLOSE]: { alt: IMAGE_ALT.CLOSE, src: closeIcon },
	[ICON_TYPE.LOGO]: { alt: IMAGE_ALT.LOGO, src: logoIcon },
	[ICON_TYPE.EYE]: { alt: IMAGE_ALT.HIDE_PASSWORD, src: eyeIcon },
	[ICON_TYPE.EYE_OFF]: { alt: IMAGE_ALT.HIDE_PASSWORD, src: eyeOffIcon },
	[ICON_TYPE.FILTERS]: { alt: IMAGE_ALT.FILTERS, src: filtersIcon },
	[ICON_TYPE.DRAWER_WHITE]: { alt: IMAGE_ALT.DRAWER_WHITE, src: drawerWhiteIcon },
	[ICON_TYPE.CALENDAR_WHITE]: { alt: IMAGE_ALT.CALENDAR, src: calendarWhiteIcon }
};

export const SIDE_MENU_ICONS: Record<SIDE_MENU_ICON_TYPE, ISidebarIcon>  = {
	[SIDE_MENU_ICON_TYPE.SIDE_MENU_CONTAINER]: {
		activeIcon: containerGreenIcon,
		passiveIcon: containerGrayIcon,
		title: 'Containers',
		type: CONTROL_TYPES.CONTAINERS,
	},
	[SIDE_MENU_ICON_TYPE.SIDE_MENU_TREND]: {
		activeIcon: trendGreenIcon,
		passiveIcon: trendGrayIcon,
		title: 'Analytics',
		type: CONTROL_TYPES.ANALYTICS,
	},
	[SIDE_MENU_ICON_TYPE.SIDE_MENU_LOGOUT_RED]: {
		activeIcon: logoutRedIcon,
		passiveIcon: logoutRedIcon,
		title: 'Log Out',
		type: CONTROL_TYPES.LOGOUT,
	},
};
