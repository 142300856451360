export const getTransformAnalyticContainers = (containers: number[]) => {
  if (!containers.length) {
    return [];
  }

  return containers.map((containerNum, i) => ({
    containerNum,
    isOpen: i === 0 ? true : false,
  }));
};
