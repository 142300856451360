<template>
  <div class="error-toast" >
    <CloseButton
      @click="() => clearErrorMessage()"  
    />

    <p>{{ message }}</p>
  </div>
</template>

<script setup lang="ts">
import CloseButton from '@/components/shareable/buttons/CloseButton/CloseButton.vue';

import type { IErrorToastProps } from '@/core/types/components/interfaces';

import { useSystemStore } from '@/stores/systemStore';

const { clearErrorMessage } = useSystemStore();

defineProps<IErrorToastProps>();
</script>

<style scoped>
@import './ErrorToast.scss';
</style>