import staresMarkIcon from '../../../assets/icons/container/stress-mark-white-bg.svg';
import checkMarkIcon from '../../../assets/icons/container/check-mark-white-bg.svg';

import { CONNECTION_STATUS_VARIANT, STATUS } from '@/core/types/components/enums';
import type { IConnectionStatusState } from '@/core/types/components/interfaces';

export const CONNECTION_STATUS: IConnectionStatusState = {
  connected: {
    icon: checkMarkIcon,
    status: STATUS.NORMAL,
    title: CONNECTION_STATUS_VARIANT.CONNECTED,
  },
  lost: {
    icon: staresMarkIcon,
    status: STATUS.ERROR,
    title: CONNECTION_STATUS_VARIANT.LOST,
  },
};
