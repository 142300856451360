<template>
	<img
		:class="className"
		:alt="ARCTIC_ICONS[iconType].alt"
		:src="ARCTIC_ICONS[iconType].src"
		@click="() => emit('click')"
	/>
</template>

<script lang="ts" setup>
// constants
import { ARCTIC_ICONS } from '@/core/constants/icons/arctic_icons.constant';
// types
import { type ICustomIconEmits, type ICustomIconProps } from '@/core/types/components/interfaces';

const emit = defineEmits<ICustomIconEmits>();

defineProps<ICustomIconProps>();
</script>

<style lang="scss" scoped>
@import './CustomIcon.scss'
</style>
