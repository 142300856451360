import type { LOCAL_STORAGE_KEYS } from "@/core/types/api/enums";

class LocalStorageService {
  static set = (key: LOCAL_STORAGE_KEYS, value: any) => localStorage.setItem(key, value);

  static read = (key: LOCAL_STORAGE_KEYS) => localStorage.getItem(key);

  static remove = (key: LOCAL_STORAGE_KEYS) => localStorage.removeItem(key);
};

export { LocalStorageService };