<template>
	<button
		:class="`filled-button ripple ${color} ${bgColor} ${borderColor} ${disabled ? 'disabled' : ''}`"
		:style="{ width: width || '100%', height: height || '42px' }"
		@click="$event => emit('click', $event)"
	>
		<CustomIcon v-if="!!iconType" :icon-type="iconType" :class-name="'button__icon'" />
		{{ text }}
	</button>
</template>

<script setup lang="ts">
// components
import CustomIcon from '@/components/shareable/CustomIcon/CustomIcon.vue';
// types
import type { IButtonProps, IButtonEmits } from '@/core/types/components/interfaces';

defineProps<IButtonProps>();

const emit = defineEmits<IButtonEmits>();
</script>

<style scoped lang="scss">
@import './FilledButton.scss';
</style>
