<template>
  <Header v-if='!isLogin' />

  <SideMenu v-if='!isLogin' />

  <main>
    <RouterView/>
  </main>

  <ModalLayout
    v-for="item in activeModals"
    :key="item.name"
    :name="item.name"
  >
    <component :is="toRaw(item.modal)" />
  </ModalLayout>

  <ErrorToast 
    v-if="getIsErrorShow" 
    :message="errorMessage"
  />

  <LoaderLayout v-if="isLoading" />

  <AnimatedBackground />
</template>

<script setup lang="ts">
import Header from '@/components/Header/Header.vue';
import SideMenu from '@/components/SideMenu/SideMenu.vue';
import ModalLayout from '@/layouts/ModalLayout/ModalLayout.vue';
import ErrorToast from '@/components/ErrorToast/ErrorToast.vue';
import LoaderLayout from '@/layouts/LoaderLayout/LoaderLayout.vue';
import AnimatedBackground from '@/components/AnimatedBackground/AnimatedBackground.vue';

import { useModalStore } from '@/stores/modalStore';
import { useSystemStore } from '@/stores/systemStore';

import { toRaw } from 'vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

import { storeToRefs } from 'pinia';


const { isLoading, getIsErrorShow, errorMessage } = storeToRefs(useSystemStore());

const { activeModals } = storeToRefs(useModalStore());

const route = useRoute();

const isLogin = computed(() => route.path === '/login');
</script>

<style scoped lang="scss">
main {
  position: relative;
  display: flex;
}
</style>
