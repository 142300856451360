export enum ENDPOINTS {
  LOGIN = "login",
  ALL_CONTAINERS_DATA = "data",
  CONTAINER_DATA = "data",
  SET_CONTAINER_PARAMS = "data",

  PARAMETERS = "parameters",
  PARAMETER_PUMPS = "parameter/pumps",
  PARAMETER_FANS = "parameter/fans",
  PARAMETER_LIQUIDS = "parameter/liquids",
  PARAMETER_TEMP_INPUT = "parameter/temp-input",
  PARAMETER_TEMP_OUTPUT = "parameter/temp-output",
  CONNECTION = "connection",
  OPERATION_MODE = "operation-mode",
  CHANGING_PARAMETERS = "changing-parameters",
  SWITCHING_CHART = "switching-chart",
}
