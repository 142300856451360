export enum CMD {
  CONTAINERS_LIST = 'get_container_list',
  CONTAINER_DATA = 'get_container_data',
  SET_CONTAINER_PARAM = 'set_container_param',
};

export enum AUTO_MODE {
  MANUAL = 'manual',
  AUTO = 'auto',
};
