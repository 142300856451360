// types
import {
  ANALYTICS_COLORS_PALETTE,
  CHART_TYPE,
} from "@/core/types/components/enums";
import type { IPieChartData } from "@/core/types/components/interfaces";

export const COLUMN_CHART_OPTIONS = {
  chart: {
    type: "bar",
    height: 450,
    width: "100%",
    stacked: true,
    stackType: "normal",
    toolbar: {
      autoSelected: "zoom",
      show: false,
    },
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 1,
      animateGradually: {
        enabled: true,
        delay: 2,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 1,
      },
    },
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: false,
  },
  xaxis: {
    labels: {
      style: {
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
    },
  },
  tooltip: {
    custom: function () {
      return `<div class="__column-chart-tooltip"></div>`;
    },
  },
};

export const TIMELINE_CHART_OPTIONS = {
  chart: {
    type: "rangeBar",
    toolbar: {
      autoSelected: "zoom",
      show: false,
    },
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
      type: "x",
    },
  },
  noData: {
    text: "NO DATA",
    align: "top",
    verticalAlign: "top",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  legend: {
    show: false,
  },
  xaxis: {
    type: "datetime",
    forceNiceScale: true,
    labels: {
      style: {
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
      formatter: function (value: string) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        return `${day}/${month}`;
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "16px",
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
    },
  },
  responsive: [
    {
      breakpoint: 376,
      options: {
        chart: {
          height: "252px",
        },
      },
    },
  ],
};

export const TIMELINE_MULTI_CHART_OPTIONS = {
  chart: {
    height: 650,
    width: "100%",
    type: "rangeBar",
    toolbar: {
      autoSelected: "zoom",
      show: false,
    },
  },
  noData: {
    text: "NO DATA",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  animations: {
    enabled: true,
    easing: "easeinout",
    speed: 1,
    animateGradually: {
      enabled: true,
      delay: 2,
    },
    dynamicAnimation: {
      enabled: true,
      speed: 1,
    },
  },
  redrawOnWindowResize: true,
  zoom: {
    enabled: false,
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "80%",
      borderRadius: 0,
    },
  },
  xaxis: {},
  yaxis: {
    labels: {
      style: {
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
    },
  },
  stroke: {
    width: 1,
  },
  fill: {
    type: "solid",
    opacity: 1,
  },
  legend: {
    show: false,
  },
  tooltip: {
    custom: function () {
      return `<div class="__column-chart-tooltip"></div>`;
    },
  },
};

export const LINE_CHART_OPTIONS = {
  chart: {
    height: 350,
    type: "line",
    toolbar: {
      autoSelected: "zoom",
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  noData: {
    text: "NO DATA",
    align: "top",
    verticalAlign: "top",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    lineCap: "round",
    width: 5,
  },
  markers: {
    size: 5,
    shape: "circle",
    radius: 2,
    hover: {
      size: 1,
      sizeOffset: 1,
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
        title: {
          style: {
            color: ANALYTICS_COLORS_PALETTE.WHITE,
          },
        },
      },
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        fontSize: "14px",
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
      formatter: function (value: string) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        return `${day}/${month}`;
      },
    },
  },
  grid: {
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  responsive: [
    {
      breakpoint: 376,
      options: {
        chart: {
          height: "252px",
        },
      },
    },
  ],
};

export const AREA_CHART_OPTIONS = {
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  noData: {
    text: "NO DATA",
    align: "top",
    verticalAlign: "top",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  colors: [
    ANALYTICS_COLORS_PALETTE.LIGHT_PINK,
    ANALYTICS_COLORS_PALETTE.LIGHT_GREEN,
  ],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    connectNulls: false,
  },
  markers: {
    showNullDataPoints: false,
  },
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        fontSize: "14px",
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
      formatter: function (value: string) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        return `${day}/${month}`;
      },
      fill: {
        // Disables filling when the value is null
        fillNullData: false,
      },
    },
    dropNulls: true,
  },
  legend: {
    position: "top",
    fontSize: "16px",
    offsetY: -20,
    height: 60,
    markers: {
      showNullDataPoints: false,
      width: 14,
      height: 14,
      fillColors: [
        ANALYTICS_COLORS_PALETTE.LIGHT_PINK,
        ANALYTICS_COLORS_PALETTE.LIGHT_GREEN,
      ],
      radius: 14,
    },
    labels: {
      colors: [ANALYTICS_COLORS_PALETTE.WHITE, ANALYTICS_COLORS_PALETTE.WHITE],
    },
    itemMargin: {
      horizontal: 10,
      vertical: 0,
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "14px",
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
    },
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
  responsive: [
    {
      breakpoint: 376,
      options: {
        chart: {
          height: "260px",
        },
        legend: {
          fontSize: "13px",
          offsetY: 0,
          width: "140%",
          height: 0,
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
        },
      },
    },
  ],
};

const PIE_PARAMETERS_CHART_OPTION_CONFIG = {
  chart: {
    width: "100%",
    type: "pie",
  },
  noData: {
    text: "NO DATA",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: -10,
        minAngleToShowLabel: 15,
      },
    },
  },
  stroke: {
    show: true,
    curve: "straight",
    lineCap: "butt",
    colors: ANALYTICS_COLORS_PALETTE.DARK_BLUE,
    width: 1.5,
    dashArray: 0,
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
    textAnchor: "middle",
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    orientation: "horizontal",
    style: {
      fontSize: "12px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      color: ANALYTICS_COLORS_PALETTE.WHITE,
    },
    background: {
      enabled: false,
      foreColor: ANALYTICS_COLORS_PALETTE.WHITE,
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: ANALYTICS_COLORS_PALETTE.WHITE,
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: ANALYTICS_COLORS_PALETTE.BLACK,
        opacity: 0.45,
      },
    },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: ANALYTICS_COLORS_PALETTE.BLACK,
      opacity: 0.45,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: "14px",
    formatter: undefined,
    inverseOrder: false,
    width: 0,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: ANALYTICS_COLORS_PALETTE.WHITE,
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: false,
      offsetX: -8,
      offsetY: 0,
    },
    containerMargin: {
      left: 100,
      top: 0,
    },
    itemMargin: {
      horizontal: 8,
      vertical: 4,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  tooltip: {
    enabledOnSeries: undefined,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    hideEmptySeries: true,
    fillSeriesColor: false,
    theme: false,
    style: {
      fontSize: "32px",
      fontFamily: "Helvetica, Arial, sans-serif",
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      format: "dd MMM",
      formatter: undefined,
    },
    y: {
      formatter: undefined,
      title: {
        formatter: (seriesName: string) => seriesName,
      },
    },
    z: {
      formatter: undefined,
      title: "Size: ",
    },
    marker: {
      show: true,
    },
    items: {
      display: "flex",
    },
    fixed: {
      enabled: false,
      position: "topRight",
      offsetX: 0,
      offsetY: 0,
    },
  },
  responsive: [
    {
      breakpoint: 1460,
      options: {
        legend: {
          itemMargin: {
            horizontal: 14,
            vertical: 4,
          },
        },
      },
    },
    {
      breakpoint: 1330,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "12",
          },
        },
        legend: {
          itemMargin: {
            horizontal: 14,
            vertical: 4,
          },
        },
      },
    },
    {
      breakpoint: 1220,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "12",
          },
        },
      },
    },
    {
      breakpoint: 1100,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "8",
          },
        },
        legend: {
          containerMargin: {
            left: 0,
            top: 0,
          },
          itemMargin: {
            horizontal: 12,
            vertical: 4,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -8,
            offsetY: 0,
          },
        },
      },
    },
    {
      breakpoint: 800,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "8",
          },
        },
        legend: {
          containerMargin: {
            left: 0,
            top: 0,
          },
          itemMargin: {
            horizontal: 4,
            vertical: 2,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -8,
            offsetY: 0,
          },
        },
      },
    },
  ],
};

const PIE_CONNECTING_CHART_OPTION_CONFIG = {
  colors: [ANALYTICS_COLORS_PALETTE.GREEN, "transparent"],
  chart: {
    width: "100%",
    type: "pie",
  },
  noData: {
    text: "NO DATA",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: -10,
        minAngleToShowLabel: 90,
      },
    },
  },
  stroke: {
    show: false,
    curve: "straight",
    lineCap: "butt",
    colors: ANALYTICS_COLORS_PALETTE.DARK_BLUE,
    width: 1.5,
    dashArray: 0,
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
    textAnchor: "middle",
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    orientation: "horizontal",
    style: {
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      colors: [ANALYTICS_COLORS_PALETTE.BLACK],
    },
    background: {
      enabled: false,
      foreColor: ANALYTICS_COLORS_PALETTE.WHITE,
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: ANALYTICS_COLORS_PALETTE.WHITE,
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: ANALYTICS_COLORS_PALETTE.WHITE,
        opacity: 0.45,
      },
    },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: ANALYTICS_COLORS_PALETTE.BLACK,
      opacity: 0.45,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 20,
    formatter: undefined,
    inverseOrder: false,
    width: 0,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: ANALYTICS_COLORS_PALETTE.WHITE,
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: -8,
      offsetY: 0,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  tooltip: {
    enabledOnSeries: undefined,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    hideEmptySeries: true,
    fillSeriesColor: false,
    theme: false,
    style: {
      fontSize: "32px",
      fontFamily: "Helvetica, Arial, sans-serif",
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      format: "dd MMM",
      formatter: undefined,
    },
    y: {
      formatter: undefined,
      title: {
        formatter: (seriesName: string) => seriesName,
      },
    },
    z: {
      formatter: undefined,
      title: "Size: ",
    },
    marker: {
      show: true,
    },
    items: {
      display: "flex",
    },
    fixed: {
      enabled: false,
      position: "topRight",
      offsetX: 0,
      offsetY: 0,
    },
  },
  responsive: [
    {
      breakpoint: 1460,
      options: {
        legend: {
          itemMargin: {
            horizontal: 14,
            vertical: 4,
          },
        },
      },
    },
    {
      breakpoint: 1330,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "12",
          },
        },
        legend: {
          itemMargin: {
            horizontal: 14,
            vertical: 4,
          },
        },
      },
    },
    {
      breakpoint: 1220,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "12",
          },
        },
      },
    },
    {
      breakpoint: 1100,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "8",
          },
        },
        legend: {
          containerMargin: {
            left: 0,
            top: 0,
          },
          itemMargin: {
            horizontal: 12,
            vertical: 4,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -8,
            offsetY: 0,
          },
        },
      },
    },
    {
      breakpoint: 800,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "8",
          },
        },
        legend: {
          containerMargin: {
            left: 0,
            top: 0,
          },
          itemMargin: {
            horizontal: 4,
            vertical: 2,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -8,
            offsetY: 0,
          },
        },
      },
    },
  ],
};

const PIE_MODE_CHART_OPTION_CONFIG = {
  colors: [ANALYTICS_COLORS_PALETTE.LIGHT_BLUE, ANALYTICS_COLORS_PALETTE.BLUE],
  chart: {
    width: "100%",
    type: "pie",
  },
  noData: {
    text: "NO DATA",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: -10,
        minAngleToShowLabel: 10,
      },
    },
  },
  stroke: {
    show: true,
    curve: "straight",
    lineCap: "butt",
    colors: ANALYTICS_COLORS_PALETTE.DARK_BLUE,
    width: 1.5,
    dashArray: 0,
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
    textAnchor: "middle",
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    orientation: "horizontal",
    style: {
      fontSize: "12px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      colors: [ANALYTICS_COLORS_PALETTE.BLACK, ANALYTICS_COLORS_PALETTE.WHITE],
    },
    background: {
      enabled: false,
      foreColor: ANALYTICS_COLORS_PALETTE.WHITE,
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: ANALYTICS_COLORS_PALETTE.WHITE,
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: ANALYTICS_COLORS_PALETTE.WHITE,
        opacity: 0.45,
      },
    },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: ANALYTICS_COLORS_PALETTE.BLACK,
      opacity: 0.45,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 20,
    formatter: undefined,
    inverseOrder: false,
    width: 0,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: ANALYTICS_COLORS_PALETTE.WHITE,
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: -8,
      offsetY: 0,
    },
    containerMargin: {
      left: 0,
      top: 0,
    },
    itemMargin: {
      horizontal: 14,
      vertical: 4,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  tooltip: {
    enabledOnSeries: undefined,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    hideEmptySeries: true,
    fillSeriesColor: false,
    theme: false,
    style: {
      fontSize: "32px",
      fontFamily: "Helvetica, Arial, sans-serif",
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      format: "dd MMM",
      formatter: undefined,
    },
    y: {
      formatter: undefined,
      title: {
        formatter: (seriesName: string) => seriesName,
      },
    },
    z: {
      formatter: undefined,
      title: "Size: ",
    },
    marker: {
      show: true,
    },
    items: {
      display: "flex",
    },
    fixed: {
      enabled: false,
      position: "topRight",
      offsetX: 0,
      offsetY: 0,
    },
  },
  responsive: [
    {
      breakpoint: 1460,
      options: {
        legend: {
          itemMargin: {
            horizontal: 14,
            vertical: 4,
          },
        },
      },
    },
    {
      breakpoint: 1330,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "12",
          },
        },
        legend: {
          itemMargin: {
            horizontal: 14,
            vertical: 4,
          },
        },
      },
    },
    {
      breakpoint: 1220,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "12",
          },
        },
      },
    },
    {
      breakpoint: 1100,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "8",
          },
        },
        legend: {
          containerMargin: {
            left: 0,
            top: 0,
          },
          itemMargin: {
            horizontal: 12,
            vertical: 4,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -8,
            offsetY: 0,
          },
        },
      },
    },
    {
      breakpoint: 800,
      options: {
        chart: {
          width: "100%",
          height: "300px",
        },
        dataLabels: {
          style: {
            fontSize: "8",
          },
        },
        legend: {
          containerMargin: {
            left: 0,
            top: 0,
          },
          itemMargin: {
            horizontal: 4,
            vertical: 2,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: ANALYTICS_COLORS_PALETTE.WHITE,
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -8,
            offsetY: 0,
          },
        },
      },
    },
  ],
};

export const CHART_OPTION_CONFIGS = {
  [CHART_TYPE.PIE_MODE]: PIE_MODE_CHART_OPTION_CONFIG,
  [CHART_TYPE.PIE_CONNECTING]: PIE_CONNECTING_CHART_OPTION_CONFIG,
  [CHART_TYPE.PIE_PARAMETERS]: PIE_PARAMETERS_CHART_OPTION_CONFIG,

  [CHART_TYPE.LINE_TIME]: {},
  [CHART_TYPE.LINE]: {},
  [CHART_TYPE.AREA]: {},
  [CHART_TYPE.DETAILS]: {},
};

const pieChartParametersMockData: IPieChartData[] = [
  {
    id: 1,
    name: "User 1",
    value: 15,
  },
  {
    id: 2,
    name: "User 2",
    value: 20,
  },
  {
    id: 3,
    name: "User 3",
    value: 30,
  },
  {
    id: 4,
    name: "User 4",
    value: 35,
  },
];

const pieChartConnectMockData: IPieChartData[] = [
  {
    id: 1,
    name: "Connect",
    value: 90,
  },
  {
    id: 2,
    name: "Disconnect",
    value: 10,
  },
];

const pieChartModeMockData: IPieChartData[] = [
  {
    id: 1,
    name: "Automatic mode",
    value: 80,
  },
  {
    id: 2,
    name: "Manual mode",
    value: 20,
  },
];

export const PIE_CHART_DATA = {
  [CHART_TYPE.PIE_MODE]: pieChartModeMockData,
  [CHART_TYPE.PIE_CONNECTING]: pieChartConnectMockData,
  [CHART_TYPE.PIE_PARAMETERS]: pieChartParametersMockData,
};

export const MULTI_LINE_CHART_OPTIONS = {
  chart: {
    height: 350,
    type: "line",
    toolbar: {
      autoSelected: "zoom",
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  noData: {
    text: "NO DATA",
    align: "top",
    verticalAlign: "top",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "14px",
      fontFamily: undefined,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  stroke: {
    curve: "straight",
    colors: [ANALYTICS_COLORS_PALETTE.DARK_GREEN],
  },
  markers: {
    showNullDataPoints: false,
  },
  title: {
    align: "left",
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  yaxis: {
    stepSize: 10,
    labels: {
      style: {
        colors: ["#FFFFFF"],
        fontSize: "12px",
      },
      formatter: (val: string) => {
        return val + " RPM";
      },
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        fontSize: "14px",
        colors: ANALYTICS_COLORS_PALETTE.WHITE,
      },
      formatter: function (value: string) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        return `${day}/${month}`;
      },
    },
  },
  grid: {
    borderColor: "#90A4AE",
    row: {
      colors: ["#484a52", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
};
