export enum FILTER_KEYS {
  CONTAINER_NUMBER = "container_",
  LEFT = "left",
  RIGHT = "right",
  FANS = "fans",
  PUMPS = "pumps",
  LEVEL = "level",
  TEMPERATURE = "temperature",
  FAN = "fan_",
  PUMP = "pump_",
  TANK = "tank_",
  ALL = "all",
}

export enum FILTER_TYPES {
  CONTOUR = "contour",
  PARAMETERS = "parameters",
  CONTOUR_DETAILS = "contour_details",
  FANS_DETAILS = "fans_details",
}
