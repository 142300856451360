export enum TEXT_COLORS {
  BLACK = 'black-txt',
  WHITE = 'white-txt',
  GRAY = 'gray-txt',
  GREEN = 'green-txt',
};

export enum BG_COLORS {
  GREEN = 'green-bg',
  WHITE = 'white-bg',
  GRAY = 'gray-bg',
  DARK_GREEN = 'dark-green-bg',
  TRANSPARENT = 'transparent',
};

export enum BORDER_COLORS {
  WHITE = 'grey-border',
  GREEN = 'green-border',
};
