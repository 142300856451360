<template>
  <div
    class="parameter-item"
  >
    <div class="parameter-item__title">
      {{ title }}
    </div>

    <div 
      :class="`parameter-item__values ${unit === PARAMETER_UNITS.MODE && 'reverse'}`"
    >
      <div 
        v-if="!inputOnly"
        class="parameter-item__values--actual"
      >
        <p>C.P.</p>

        <span>{{ actualValue }}</span>
        
        <span v-if="unit !== PARAMETER_UNITS.MODE">{{ unit }}</span>
      </div>

      <div 
        class="parameter-item__values--new"
      >
        <p>N.P.</p>

        <input
          type="number"
          :value="userValue"
          @input="inputHandler"
        >

        <span v-if="unit !== PARAMETER_UNITS.MODE">{{ unit }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IParameterItemProps, IParameterItemEmits } from '@/core/types/components/interfaces';
import { PARAMETER_UNITS } from '@/core/types/components/enums';

import { useContainerStore } from '@/stores/containerStore';

const props = defineProps<IParameterItemProps>();
const emit = defineEmits<IParameterItemEmits>();

const { setNewManualParameterValue, setNewAdditionalParameterValue } = useContainerStore();

function inputHandler(e: Event) {
  if (!e || e.target === null) return;

  const target = e.target as HTMLInputElement;

  if (props.manualParameterName) {
    setNewManualParameterValue(props.manualParameterName, +target.value);

    return;
  };

  if (props.additionalParameterName) {
    setNewAdditionalParameterValue(props.additionalParameterName, +target.value);

    return;
  }
};


</script>

<style scoped lang="scss">
@import './ParameterItem.scss';
</style>