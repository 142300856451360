<template>
  <header class="header">
    <img alt="logo" src="@/assets/icons/general/logo.svg" />

    <div class="header__btns">
      <button>
        <img alt="button" src="@/assets/icons/general/headphone.svg" />
      </button>

      <button>
        <img alt="button" src="@/assets/icons/general/bell.svg" />
      </button>

      <button>
        <img alt="button" src="@/assets/icons/general/user.svg" />
      </button>
    </div>
  </header>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
@import './Header.scss';
</style>