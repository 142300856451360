<template>
  <div class="additional-parameters">
    <div class="additional-parameters__header">
      <h4>ADDITIONAL PARAMETERS</h4>

      <h4>NEW PARAMETERS</h4>
    </div>

    <ParameterItem 
      v-for="item in items"
      :title="item.title" 
      :unit="item.unit" 
      :additional-parameter-name="item.additionalParameterName"
      :actual-value="item.actualValue"
      :user-value="item.userValue" 
    />

    <!-- <ParameterItem 
      :title="PARAMETER_UNITS.MODE" 
      :unit="PARAMETER_UNITS.MODE"
      :parameter-name="MANUAL_PARAMETERS_MODEL_KEYS.MODE"
      :actual-value="manualModeData?.mode || 0" 
    /> -->
    <div 
      class="certain"
      v-if="!isActive"
    />
  </div>
</template>

<script setup lang="ts">
import ParameterItem from '../ParameterItem/ParameterItem.vue';

import {
  PARAMETER_UNITS,
  MANUAL_PARAMETERS_MODEL_KEYS
} from '@/core/types/components/enums';
import type { IParametersListProps } from '@/core/types/components/interfaces';

import { useContainerStore } from '@/stores/containerStore';
import { storeToRefs } from 'pinia';

defineProps<IParametersListProps>();

const { manualModeData } = storeToRefs(useContainerStore());
</script>

<style scoped>
@import './AdditionalParametersList.scss';
</style>