import ChangeParametersVue from "@/components/modals/ChangeParameters/ChangeParameters.vue";
import { MODAL_NAMES } from "../../types/components/enums";
import type { IModal } from "../../types/components/interfaces";


export const MODALS_CONFIG: IModal[] = [
  { 
    name: MODAL_NAMES.PARAMETERS, 
    modal: ChangeParametersVue,
  },
];
