<template>
  <div class="loader_wrap">
    <div class="loader_wrap__items">
      <div 
        v-for="item in items"
        :class="`loader_wrap__items--item i${item}`"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const items: number[] = Array.from(Array(6).keys());
</script>

<style lang="scss" scoped>
@import './LoaderLayout.scss';
</style>