<template>
  <div
    class="close-button"
    @click="() => emit('click')"
  >
    <img 
      alt="close"
      :src="close"
    />
  </div>
</template>

<script setup lang="ts">
import close from '@/assets/icons/general/close-btn.svg';
import type { IModalParametersEmit } from '@/core/types/components/interfaces';

const emit = defineEmits<IModalParametersEmit>();
</script>

<style scoped lang="scss">
@import './CloseButton.scss';
</style>